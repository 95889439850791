var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-screen container mx-auto min-contents" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "grid grid-cols-12 gap-6 sm:grid-cols-1 sm:gap-1" },
      [
        _vm._m(1),
        _c("div", { staticClass: "help-sumally" }, [
          _c(
            "div",
            { staticClass: "help-index border-2 border-base-yellow mb-8 p-4" },
            [
              _c(
                "h4",
                { staticClass: "font-bold text-xl text-left mt-4 mb-2 ml-4" },
                [_vm._v("他のヘルプを見る")]
              ),
              _c(
                "div",
                { staticClass: "p-4" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/" },
                    },
                    [_vm._v(" 主な流れ ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/account" },
                    },
                    [_vm._v(" 新規アカウント登録 ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/product-register" },
                    },
                    [_vm._v(" 作品登録(単体登録) ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/product-register-all" },
                    },
                    [_vm._v(" 作品登録(一括登録) ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/product-list" },
                    },
                    [_vm._v(" 映画一覧の確認と編集 ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/quotation" },
                    },
                    [_vm._v(" 見積書作成 ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/upload" },
                    },
                    [_vm._v(" 納品アップロード ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/invoice" },
                    },
                    [_vm._v(" 請求書について ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/youtube-limit" },
                    },
                    [_vm._v(" Youtubeでの限定公開の方法がわかりません ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/vimeo-register" },
                    },
                    [_vm._v(" Vimeoで動画登録を行いたいです ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/vimeo-limit" },
                    },
                    [_vm._v(" Vimeoでの限定公開の方法がわかりません ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/password" },
                    },
                    [_vm._v(" アカウントのパスワードを忘れました ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/email" },
                    },
                    [_vm._v(" 新規アカウント登録完了メールが届きません ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-full rounded overflow-hidden my-4" }, [
      _c("div", { staticClass: "px-6 py-4" }, [
        _c("h2", { staticClass: "text-center font-bold text-3xl" }, [
          _vm._v("納品アップロード"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-span-9 flex flex-col justify-center sm:col-span-1" },
      [
        _c(
          "div",
          {
            staticClass: "w-full rounded overflow-hidden shadow-lg mb-6",
            attrs: { id: "help-ch-06" },
          },
          [
            _c("div", { staticClass: "px-6 pb-12" }, [
              _c("ul", { staticClass: "text-base" }, [
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _vm._v(
                      "フィルミネーションのプラットフォーム上では、販売だけなく、納品が可能です。"
                    ),
                  ]),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("1.")]
                    ),
                    _vm._v(
                      " 登録したアカウントでログイン後、「見積もり一覧」を押してください。 "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/06-01.jpg"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("2.")]
                    ),
                    _vm._v(
                      " 見積もり一覧に遷移しましたら、「アップロード待ち」を押してください。アップロード待ちタブに遷移します。 "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/06-02.jpg"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("3.")]
                    ),
                    _vm._v(
                      " アップロード待ちタブに遷移しましたら、今回納品する内容を、押してください。 "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/06-03.jpg"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("4.")]
                    ),
                    _vm._v(
                      " 当該発注書で納品すべき作品の一覧が表示されますので、各バーを押してください。 "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/06-04.jpg"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("5.")]
                    ),
                    _vm._v(
                      " 当該発注書の条件が記載された納品ページが表示されます。 "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/06-05.jpg"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("6.")]
                    ),
                    _vm._v(
                      " 同画面の下に移動すると、納品モジュールが表示されます。"
                    ),
                    _c("br"),
                    _vm._v(
                      " 今回納品が必要なDATA分、モジュールが表示されています。 "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/06-06.jpg"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("7.")]
                    ),
                    _vm._v(
                      " 各モジュール上部にある納品物の内容を確認してください。"
                    ),
                    _c("br"),
                    _vm._v(
                      " 納品準備されているDATAと同じ内容であることを確認し、確認ができましたら、チェックボックスに、チェックを入れてください。チェックをいれると、各モジュールのファイル選択部分の背景がグレーから白に代わります。 "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/06-07.jpg"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("8.")]
                    ),
                    _vm._v(
                      " 納品物を、チェックをいれた各モジュールに、ドラッグ&ドロップするか、もしくは、ファイルを選択してください。"
                    ),
                    _c("br"),
                    _vm._v(
                      "画像ファイルなどで、ファイルが複数ある場合には、同時にドラッグ&ドロップすることが可能です。"
                    ),
                    _c("br"),
                    _vm._v(
                      "なお、本編などの大容量ファイルの場合、インターネット環境によりますが、ある程度の時間が必要です。"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      " 弊社のテストでは1G/分程度かかっています。またアップロード中は、ブラウザを閉じないようにお願いいたします。ブラウザを閉じた場合は、最初からやり直しになります。 "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/06-08.jpg"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("9.")]
                    ),
                    _vm._v(
                      " 納品用モジュールの一番下に、その他の枠がございます。"
                    ),
                    _c("br"),
                    _vm._v(
                      " これは、例えば、購入者様より予定にはなかった納品物の要望が合った場合で、御社が対応できる場合に、そのDATAを納品するために使用するための枠になります。ですので、通常の納品は使用しません。"
                    ),
                    _c("br"),
                    _vm._v(
                      " 不要な場合には、この枠は無視して進めていただければと思います。 "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/06-09.jpg"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("10.")]
                    ),
                    _vm._v(
                      " 納品物のアップロード中は、残りのDATA容量の％が表示されます。"
                    ),
                    _c("br"),
                    _vm._v(
                      " アップロードが完了すると、緑の枠内に、「アップロード済」の文字が表示されます。"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      " ※ご注意点：アップロード中に他の画面へ遷移をしないようにお願いいたします"
                    ),
                    _c("br"),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/06-10.jpg"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("11.")]
                    ),
                    _vm._v(" すべての納品物が、アップロードできましたら、"),
                    _c("br"),
                    _vm._v(
                      " 「納品する」ボタンがオレンジ色になりますので、押してください。"
                    ),
                    _c("br"),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/06-11.jpg"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("12.")]
                    ),
                    _vm._v(
                      " 納品完了しました。というポップアップがでますのでOKを押してください。"
                    ),
                    _c("br"),
                    _vm._v(
                      " 一番下の「納品する」ボタンが「納品済み」に切り替われば、納品は完了です。"
                    ),
                    _c("br"),
                    _vm._v(" ありがとうございました。 "),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/06-12.jpg"),
                      alt: "",
                    },
                  }),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }